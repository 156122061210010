@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-tabs/style/react-tabs';
@import 'node_modules/react-toastify/scss/main';

.react-tabs__tab {
    outline: 0;
}

#payment-message {
    color: rgb(138, 23, 23);
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
}